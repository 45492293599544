/* montserrat-regular - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/montserrat-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('./assets/fonts/montserrat-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/montserrat-v15-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat';
}

body > #root > div {
  height: 100vh;
}

html, body, #app, #app>div {
  height: 100%
}